.login_container {
    width: 70%;
    min-height: 100vh;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.login_form_container {
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 35px;
    gap: 26px;
}

.login_form_container>.logo {
    padding-bottom: 50px;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.login_input {
    outline: none;
    border: none;
    width: 90%;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--toastify-color-light);
    margin: 5px 0;
    font-size: 14px;
    max-width: 350px;
    color: var(--ternary-color);
    border: 1px solid var(--footer-text-color);
}

.login_input::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--footer-text-color);
}

.login_input::placeholder {
    color: var(--footer-text-color);
}

.white_btn,
.green_btn {
    border: none;
    outline: none;
    padding: 12px 0;
    background-color: var(--secondary-color);
    border-radius: 20px;
    width: 80%;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.green_btn {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    margin: 10px;
    max-width: 250px;
}