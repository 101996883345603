.carousel-container {
    position: relative;
}

.carousel-image-holder {
    display: flex;
    transition: all 700ms ease;
    /* overflow: hidden; */
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.564, 1);

}

.carousel-image-holder>.carousel-item {
    position: relative;
    left: calc(var(--container-padding) * -1);
    min-width: calc(100% + calc(var(--container-padding) * 2));
    display: flex;
    /* min-width: 100%; */
    transition: all 200ms ease;
}

.carousel-image-holder>.carousel-item>picture {
    width: 100%;
}

.carousel-image-holder>.carousel-item>picture>img {
    width: inherit;
}

.carousel-image-holder>.carousel-item>.carousel-description {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    font-size: var(--banner-description-size);
    color: var(--secondary-color);
    font-weight: var(--bold-font-weight);
    font-family: 'Montserrat', sans-serif;
    transition: all 200ms ease;
    overflow-wrap: break-word;
    width: 100%;
    /* padding: var(--container-padding); */
    padding-right: 0;
    gap: 20px;
    bottom: 5%;
    padding-left: 5%;
}

.dots-holder {
    display: flex;
    position: absolute;
    bottom: var(--banner-description-size);
    /* right: calc(20px + calc(var(--container-padding) * -1)); */
    gap: 10px;
    transition: all 200ms ease;
    right: -5%;
    bottom: 6%;
}

.dots-holder>.dot {
    display: flex;
    width: 10px;
    height: 10px;
    background: var(--secondary-color);
    /* border-radius: 50%; */
    opacity: .8;
    cursor: pointer;
    transition: all 200ms ease;
    border: 1px solid transparent;
}

.dots-holder>.dot.active {
    background: var(--primary-color);
    border-color: var(--secondary-color);
    opacity: 1;
}

@media only screen and (max-width: 768px) {

    .carousel-image-holder>.carousel-item:first-child,
    .dots-holder>.dot:first-child {
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    .carousel-image-holder>.carousel-item>.carousel-description {
        /* width: 100%; */
    }
}

@media only screen and (max-width: 500px) {

    .dots-holder {
        gap: 8px;
    }

    .dots-holder>.dot {
        width: 8px;
        height: 8px;
    }
}

@media only screen and (max-width: 320px) {

    .dots-holder {
        right: 0;
    }

    .dots-holder>.dot {
        width: 8px;
        height: 8px;
    }
}