.mobile-view-submenu-container {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0vh;
    background: var(--secondary-color);
    justify-content: start;
    overflow: hidden;
    z-index: 1;
    transition: all 1000ms ease;
}

.mobile-view-submenu-container.active {
    height: 100vh;
}

.mobile-view-submenu-container>.mobile-view-submenu-item:first-child {
    margin-top: var(--navbar-height);
}

.mobile-view-submenu-container>.mobile-view-submenu-item {
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    font-size: var(--subheading-font-size);
    font-weight: var(--medium-font-weight);
    color: var(--ternary-color);
    cursor: pointer;
    width: 100%;
    padding: var(--default-padding) var(--container-padding);
    transition: all 700ms ease, color 200ms ease;
    transform: translateY(-30px);
    opacity: 0;
    user-select: none;
}

.mobile-view-submenu-container.active.submenu {
    pointer-events: none;
}

.mobile-view-submenu-container.active.submenu>.mobile-view-submenu-item {
    transform: translateX(-30px);
    opacity: 0;
}

.mobile-view-submenu-container.active>.mobile-view-submenu-item {
    transform: translateY(0) translateX(0);
    opacity: 1;
    /* transition-delay: 0ms !important; */
}

.mobile-view-submenu-container.active>.mobile-view-submenu-item:hover {
    color: var(--primary-color);
}

.mobile-view-submenu-container.active>.mobile-view-submenu-item:active {
    transform: scale(.95);
}

.mobile-view-submenu-container>.mobile-view-submenu-item:nth-of-type(1) {
    transition-delay: 0ms;
}

.mobile-view-submenu-container>.mobile-view-submenu-item:nth-of-type(2) {
    transition-delay: 30ms;
}

.mobile-view-submenu-container>.mobile-view-submenu-item:nth-of-type(3) {
    transition-delay: 60ms;
}

.mobile-view-submenu-container>.mobile-view-submenu-item:nth-of-type(4) {
    transition-delay: 90ms;
}

.mobile-view-submenu-container>.mobile-view-submenu-item:nth-of-type(5) {
    transition-delay: 120ms;
}

.mobile-view-submenu-container.active>.mobile-view-submenu-item>.submenu-item-icon {
    width: 20px;
    height: 20px;
    opacity: 0;
    transform: translateX(-5px);
    transition: all 900ms ease;
}

.mobile-view-submenu-container.active>.mobile-view-submenu-item:hover>.submenu-item-icon {
    opacity: 1;
    transform: translateX(0) translateY(0);
}

.mobile-view-submenu-container-description {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--secondary-color);
    color: var(--ternary-color);
    justify-content: start;
    overflow: hidden;
    z-index: 1;
    transition: all 200ms ease;
    opacity: 0;
    visibility: hidden;
    padding: var(--default-padding) var(--container-padding);
    gap: var(--default-padding);
    font-family: 'Montserrat', sans-serif;
    overflow: auto;
}

.mobile-view-submenu-container-description.active {
    opacity: 1;
    visibility: visible;
}

.mobile-view-submenu-container-description>.mobile-view-submenu-description-item-heading {
    font-size: var(--submediumheading-font-size);
    font-weight: var(--medium-font-weight);
    transition: all 700ms ease;
    transform: translateX(30px);
    opacity: 0;
    -webkit-user-select: none;
    user-select: none;
    margin-top: var(--navbar-height);
}

.mobile-view-submenu-container-description.active>.mobile-view-submenu-description-item-heading {
    transform: translateX(0);
    opacity: 1;
}


.mobile-view-submenu-container-description>.mobile-view-submenu-description-item-description {
    font-size: var(--subheading-font-size);
    font-weight: var(--extralight-font-weight);
    transition: all 700ms ease;
    transform: translateX(30px);
    opacity: 0;
    -webkit-user-select: none;
    user-select: none;
}

.mobile-view-submenu-container-description.active>.mobile-view-submenu-description-item-description {
    transform: translateX(0);
    opacity: 1;
}

.mobile-view-submenu-description-item-list-content {
    display: flex;
    flex-direction: column;
    gap: var(--regular-gap);
    margin-top: 30px;
    transition: all 700ms ease;
    transform: translateX(30px);
    opacity: 0;
}

.mobile-view-submenu-container-description.active>.mobile-view-submenu-description-item-list-content {
    transform: translateX(0);
    opacity: 1;
}

.mobile-view-submenu-description-item-list-content>.mobile-view-submenu-description-item-list-heading {
    font-weight: var(--regular-font-weight);
    text-transform: uppercase;
    font-size: var(--medium-font-size);
    letter-spacing: 4px;
    transition: all 500ms ease;
}

.mobile-view-submenu-description-item-list-content-subitem-holder {
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    grid-auto-flow: column;
    grid-gap: 24px;
    padding: var(--default-padding);
}

.mobile-view-submenu-description-item-list-content-subitem-holder>.mobile-view-submenu-description-item-content-subitem-item {
    position: relative;
    font-weight: var(--regular-font-weight);
    font-size: var(--subheading-font-size);
    width: fit-content;
    cursor: pointer;
    transition: all 400ms ease;
}

.mobile-view-submenu-description-item-list-content-subitem-holder>.mobile-view-submenu-description-item-content-subitem-item:hover {
    color: var(--primary-color);
}

.mobile-view-back-arrow {
    width: 28px;
    height: 28px;
    position: fixed;
    top: 40px;
    left: var(--container-padding);
    cursor: pointer;
    transform: translateY(-50%);
}