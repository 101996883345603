/* sidebar */
.sidebar {
    grid-column-start: 1;
    background-color: var(--ternary-color);
    width: 260px;
    height: calc(100vh - 75px);
    padding: 20px;
    z-index: 100;
    overflow-y: scroll;
    box-shadow: 0 0 1px #ccc;
    transition: all 0.5s ease;
    margin: 0 8px;
    box-shadow: 0px 0px 15px -3px #ccc;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
}

.sidebar.close {
    padding: 60px 0;
    width: 80px;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.menu_content {
    position: relative;
}

.menu_title {
    margin: 15px 0;
    padding: 0 20px;
    font-size: 18px;
}

.sidebar.close .menu_title {
    padding: 6px 30px;
}

.menu_title::before {
    color: #b6b6b6;
    white-space: nowrap;
}

.menu_dahsboard::before {
    content: "Dashboard";
}

.menu_editor::before {
    content: "Editor";
}

.menu_setting::before {
    content: "Setting";
}

.sidebar.close .menu_title::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 18px;
    border-radius: 12px;
    background: #ccc;
}

.menu_items {
    padding: 0;
    list-style: none;
}

.menu_items>.items {
    border: 1px solid transparent;
}

.menu_items>.items.active {
    border-color: var(--primary-color);
}

.navlink_icon {
    position: relative;
    font-size: 20px;
    min-width: 50px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 6px;
}

.navlink_icon::before {
    content: "";
    position: absolute;
    height: 100%;
    width: calc(100% + 100px);
    left: -20px;
}

/* .navlink_icon:hover {
    background: var(--primary-color);
} */

.sidebar .nav_link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0;
    border-radius: 8px;
    text-decoration: none;
    color: var(--secondary-color);
    white-space: nowrap;
    font-size: 14px;
}

.submenu_item.show_submenu {
    background-color: var(--primary-color);
    color: var(--ternary-color);
}

.sidebar.close .navlink {
    display: none;
}

.nav_link:not(.show_submenu):hover {
    color: var(--secondary-color);
    background: #1897ff50;
}

.sidebar.close .nav_link:hover {
    background: var(--ternary-color);
}

.submenu_item {
    cursor: pointer;
}

.sub-menu {
    display: none;
}

.submenu_item .arrow-left {
    position: absolute;
    right: 4px;
    display: inline-block;
    margin-right: auto;
}

.sidebar.close .sub-menu {
    display: none;
}

.show_submenu~.sub-menu {
    display: block;
}

.show_submenu .arrow-left {
    transform: rotate(90deg);
}

.sub-menu .sublink {
    text-transform: capitalize;
    padding: 15px 15px 15px 15px;
    gap: 4px;
    cursor: pointer;
}

.sub-menu .sublink.active {
    background: #1897ff50;
}

.bottom_content {
    /* position: fixed; */
    bottom: 60px;
    /* left: 0; */
    width: 260px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.bottom {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    justify-content: space-around;
    padding: 18px 0;
    text-align: center;
    width: 100%;
    color: #b6b6b6;
    border-top: 1px solid #ccc;
    background-color: var(--ternary-color);
}

.bottom i {
    font-size: 20px;
}

.bottom span {
    font-size: 18px;
}

.sidebar.close .bottom_content {
    width: 50px;
    left: 15px;
}

.sidebar.close .bottom span {
    display: none;
}

.sidebar.hoverable .collapse_sidebar {
    display: none;
}

#sidebarOpen {
    display: none;
}

@media screen and (max-width: 768px) {
    #sidebarOpen {
        font-size: 25px;
        display: block;
        margin-right: 10px;
        cursor: pointer;
        color: #b6b6b6;
    }

    .sidebar.close {
        left: -100%;
    }

    .search_bar {
        display: none;
    }

    .sidebar.close .bottom_content {
        left: -100%;
    }
}