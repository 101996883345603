.contact-popup-container {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 620px;
    max-width: 100%;
    right: 0;
    background-color: var(--secondary-color);
    z-index: 5;
    transition: all 500ms ease;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    transform: translateX(100%);
}

.contact-popup-container.active {
    transform: translateX(0);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header>svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: var(--ternary-color);
}

.contact-description {
    font-size: var(--medium-font-size);
    color: var(--ternary-color);
    font-weight: var(--medium-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
}

.contact-information {
    display: flex;
    gap: 30px;
    flex-direction: column;
}

.contact-information>.address-info {
    font-size: var(--medium-font-size);
    color: var(--ternary-color);
    font-weight: var(--regular-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
    display: flex;
    align-items: center;
    gap: 12px;
}

.contact-information>.address-info>div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.contact-information>.address-info:first-child>svg {
    width: 30px;
    height: 50px;
}

.contact-information>.address-info:nth-child(2)>svg {
    margin-top: 1px;
}

.contact-information>.address-info:nth-child(3)>svg {
    margin-top: 2px;
}

.contact-information>.address-info>svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    align-self: flex-start;
}

@media only screen and (max-width: 840px) {
    .contact-popup-container {
        width: 100%;
    }
}