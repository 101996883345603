.footer-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-bottom: var(--regular-gap);
}

.footer-column-holder {
    display: grid;
    grid-template-columns: .7fr .7fr 1fr 1fr;
    font-weight: var(--light-font-weight);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    grid-gap: 12px;
}

.footer-column {
    width: 100%;
    color: var(--footer-text-color);
}

.footer-column>ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.footer-column>ul>li.menu-heading {
    font-weight: var(--bold-font-weight);
}

.footer-column>ul.address-block>li {
    font-weight: var(--regular-font-weight);
    font-size: 14px;
}

.address-block .contact-us-heading {
    font-size: 24px;
    font-weight: var(--semibold-font-weight);
    line-height: 1;
}

.address-block>.get-in-touch-block {
    display: flex;
    gap: 6px;
}

.address-block {
    gap: 20px !important;
}

.address-block>.get-in-touch-block>input {
    display: flex;
    width: calc(100% - 50px);
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 0 12px;
    font-family: 'Poppins', sans-serif;
    font-size: var(--tiny-font-size);
    background: var(--footer-text-color);
    color: var(--secondary-color);
    height: 50px;
}

.address-block>.get-in-touch-block>button {
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    background: var(--primary-color);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    border: none;
    justify-content: center;
    border: none;
}

.address-block .address-location {
    font-weight: var(--bold-font-weight);
}

.address-block>.email-address-holder {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.address-block>.email-address-holder>li.email-address {
    display: flex;
    gap: 6px;
    align-items: center;
}

.address-block>.email-address-holder>li.email-address>svg {
    width: 20px;
    height: 20px;
}

.footer-logo {
    width: calc(100% + calc(var(--container-padding) * 2));
    position: relative;
    left: calc(var(--container-padding) * -1);
}

.terms-and-conditions {
    display: flex;
    justify-content: space-between;
    color: var(--ternary-color);
    font-family: 'Poppins', sans-serif;
    color: var(--footer-text-color);
    flex-wrap: wrap;
    gap: var(--default-padding);
}

.terms-and-conditions>ul {
    display: flex;
    gap: var(--default-padding);
    font-weight: var(--regular-font-weight);
    font-size: 16px;
    flex-wrap: wrap;
}

.terms-and-conditions>ul>li {
    cursor: pointer;
}


@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {
    .footer-column-holder {
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--regular-gap);
    }
}

@media only screen and (max-width: 960px) {}

@media only screen and (max-width: 640px) {
    .footer-column-holder {
        grid-template-columns: 1fr;
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {}

@media only screen and (max-width: 420px) {
    .footer-container {
        gap: 80px;
    }
}

@media only screen and (max-width: 320px) {}