.technology-section {
    margin-top: 100px;
}

.technology-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--regular-gap);
    grid-auto-rows: 1fr;
}

.card-holder {
    width: 100%;
    height: 100%;
    /* -webkit-perspective: 1000px;
    perspective: 1000px; */
    pointer-events: none;
    min-height: 400px;
}

.card-holder:nth-last-child(1)>.card>.front,
.card-holder:nth-last-child(2)>.card>.front {
    padding-left: inherit;
}


.card-holder:hover>.card {
    cursor: pointer;
    transform: rotateY(180deg);
}

.card-holder>.card {
    position: relative;
    display: flex;
    background: var(--ternary-color);
    padding: 18px;
    align-items: center;
    color: var(--secondary-color);
    transition: all 200ms ease, transform 1500ms;
    height: 100%;
    width: 100%;
    min-height: 280px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    font-weight: var(--medium-font-weight);
    font-family: 'Poppins', sans-serif;
    pointer-events: none;
    /* will-change: transform; */
    justify-content: center;
}

.card-holder>.card>.border-line {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border: 10px solid var(--primary-color);
}

.card-holder>.card::after,
.card-holder>.card::before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    background: var(--ternary-color);
    z-index: 1;
}

.card-holder>.card::before {
    top: 0;
    right: 0;
}

.card-holder>.card::after {
    bottom: 0;
    left: 0;
}

.card-holder>.card>.front,
.card-holder>.card>.back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    user-select: none;
    pointer-events: auto;
}

.card-holder>.card>.front {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    font-size: var(--submediumheading-font-size);
    width: calc(100% - var(--submediumheading-font-size));
    justify-content: flex-start;
    padding-left: 63px;
}


.card-holder>.card>.front>picture {
    display: flex;
    flex: 1;
}

.card-holder>.card>.front>picture>img {
    /* width: 211px; */
}

.card-holder:nth-child(9)>.card>.front,
.card-holder:nth-child(10)>.card>.front {
    /* padding: 90px; */
    justify-content: center;
    width: 250px;
}

.card-holder:nth-child(9)>.card>.front>picture>img,
.card-holder:nth-child(10)>.card>.front>picture>img {
    width: 100%;
}

.card-holder>.card>.front>p {
    padding-left: 30px;
    line-height: 1;
    word-break: break-word;
    flex: 1;
}

.card-holder>.card>.back {
    display: flex;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    padding: 22px;
    font-size: 15px;
}

@media only screen and (max-width: 1300px) {
    .card-holder>.card>.front {
        padding-left: 24px;
    }
}

@media only screen and (max-width: 1180px) {

    .card-holder>.card>.front {
        flex-direction: column;
        padding-left: 18px;
        gap: 16px;
    }

    .card-holder>.card>.front>p {
        padding: unset;
        flex: unset;
        text-align: center;
    }

    .card-holder>.card>.front>picture {
        flex: unset;
    }

    .card-holder>.card>.front>picture {
        width: 211px;
        max-width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .card-holder>.card {
        /* min-height: 350px; */
    }

    .technology-list {
        grid-template-columns: 1fr;
    }

}

@media only screen and (max-width: 768px) {
    .card-holder>.card>.front {
        flex-wrap: wrap;
        text-align: center;
    }

    .card-holder:nth-child(9)>.card>.front {
        padding: unset;
        width: 60%;
        left: 50%;
        transform: translateX(-50%);
    }

    .card-holder:nth-child(10)>.card>.front {
        padding: unset;
        width: 40%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 640px) {

    .card-holder>.card>.back {
        font-size: var(--tiny-font-size);
    }
}

@media only screen and (max-width: 480px) {
    .card-holder>.card>.front>p {
        font-size: 30px;
    }
}