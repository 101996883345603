.block-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.array-list {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.array-list>.array-list-numbering {
    padding: 8px;
    display: flex;
    align-items: center;
    background: var(--primary-color);
    align-self: center;
    border-radius: 50%;
    min-width: 30px;
    height: 30px;
    justify-content: center;
    color: var(--ternary-color);
    align-self: flex-start;
    margin-top: 12px;
}

.input-holder {
    width: 100%;
    /* height: 200px; */
    padding: 12px;
    /* margin: 16px; */
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: 'Poppins', sans-serif;
    background: var(--ternary-color);
    border-radius: 8px;
    color: var(--ternary-color);
    border: 1px solid var(--primary-color);
}

.input-holder>.header>label {
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 14px;
}

.input-area-holder {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    overflow: auto;
}

.input-area-holder>input,
.input-area-holder>textarea,
.input-area-holder>.input-area {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    border: none;
    padding: 10px;
    outline: none;
    line-height: 1.5;
    background: #f5f5f5;
    color: var(--secondary-color);
    height: inherit;
    resize: none;
    border-radius: 8px;
    /* min-height: 80px; */
}

.image-holder {
    width: -webkit-fill-available;
    height: 200px;
    padding: 12px;
    /* margin: 16px; */
    display: flex;
    flex-direction: column;
    /* gap: 4px; */
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    justify-content: space-between;
}

.image-holder>.image-name {
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 14px;
}

.image-holder>.imageWrapper {
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
    align-self: center;
}

.image-holder>.custom-file-upload {
    font-size: 12px;
    background: var(--ternary-color);
    padding: 4px;
    color: var(--secondary-color);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    background-color: #f5f5f5;
    border: 1px solid transparent;
}

.image-holder>.custom-file-upload:hover {
    background-color: transparent;
    border-color: #ccc;
}

.image-holder>.custom-file-upload>.file-dimension {
    font-weight: 600;
}

.image-holder>.file-upload {
    position: relative;
    overflow: hidden;
    max-width: fit-content;
    text-align: center;
    background: transparent;
    border: 1px solid #000;
    transition: all 0.2s ease;
}

.image-holder>.file-upload:hover {
    background: #999;
}

.image-holder>input[type="file"] {
    display: none;
}

.image-holder>.file-upload>input {}

.image-holder>.imageWrapper>img {
    object-fit: fill;
    width: 100%;
    height: 100%;
    max-width: 100px;
}

.image-holder>.image-dimension {
    font-size: 14px;
    text-align: center;
}

.save-button {
    display: flex;
    background: #f5f5f5;
    gap: 4px;
    padding: 4px 8px;
    color: var(--primary-color);
    align-items: center;
    border-radius: 4px;
    border: 1px solid transparent;
}

.save-button>i {
    color: var(--primary-color);
}

.save-button:hover {
    border-color: #ccc;
    background-color: transparent;
}