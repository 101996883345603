.admin-user-details {
    background-color: #00000052;
    position: absolute;
    inset: 0;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-user-details.show {}

.admin-user-details-container {
    width: 80%;
    /* height: 80vh; */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 15px 5px #00000021;
    padding: 20px;
    font-size: 24px;
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.admin-user-details-container-input-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    gap: 12px;
}

.admin-user-details-container-input-holder>.admin-user-input {
    display: flex;
    flex-direction: column;
}

.admin-user-details-container-input-holder>.admin-user-input>input {
    padding: 14px;
    border-radius: 4px;
    border: 2px solid #ccc;
    outline: none;
}

.admin-user-details-container-input-holder>.admin-user-input>.update-button-holder {
    display: flex;
    gap: 4px;
}

.admin-user-details-container-input-holder>.admin-user-input>.update-button-holder>input {
    border: 2px solid #ccc;
    padding: 14px;
    width: 100%;
    border-radius: 4px;
    outline: none;
}

.admin-user-details-container-input-holder>.admin-user-input>.update-button-holder>.update-btn {
    width: 100px;
    border-radius: 4px;
    background: var(--primary-color);
    color: var(--ternary-color);
    font-weight: 700;
}