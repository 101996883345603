.why-shift-section {
    margin-top: 100px;
}

.why-shift-content-section {
    display: flex;
    align-items: center;
    gap: 120px;
}

.why-shift-content-section.inverse {
    flex-direction: row-reverse;
}

.content-block.half-width {
    width: 50%;
}

.content-block.full-width {
    width: 100%;
}

.image-block {
    display: flex;
    width: 50%;
}

.image-block.end {
    justify-content: flex-end;
}


.why-shift-content-section>.full-piece-design-block {
    display: flex;
    position: relative;
    width: 80%;
    margin: auto;
}

.why-shift-content-section>.full-piece-design-block>.left-piece-design,
.why-shift-content-section>.full-piece-design-block>.right-piece-design {
    display: flex;
    background: var(--primary-color);
    width: var(--container-padding);
    min-height: 80%;
    align-self: center;
    position: absolute;
}

.why-shift-content-section>.full-piece-design-block>.left-piece-design {
    left: calc(var(--container-padding) * -1);
}

.why-shift-content-section>.full-piece-design-block>.right-piece-design {
    right: calc(var(--container-padding) * -1);
}

.why-shift-content-section>.full-piece-design-block>.s-text-content {
    position: relative;
    width: fit-content;
    background: var(--primary-color);
    /* padding: calc(var(--regular-padding) * 3); */
    font-size: var(--medium-font-size);
    color: var(--secondary-color);
    font-weight: var(--medium-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
    word-break: break-word;
}

@media only screen and (max-width: 840px) {
    .why-shift-content-section {
        flex-direction: column !important;
        gap: 30px;
    }

    .why-shift-content-section>.content-block {
        width: 100%;
    }

    .why-shift-content-section>.image-block {
        width: 100%;
        justify-content: center;
    }

    .content-block>.s-text-content {
        text-align: start;
    }

    .content-block>.b-text-content {
        text-align: start;
    }
}