.navbar-container {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--navbar-height);
    background-color: var(--secondary-color);
    z-index: 3;
    outline: none;
    padding: 0 var(--container-padding);
}

.logo {
    display: flex;
    width: 300px;
    cursor: pointer;
    transition: all 200ms ease;
    padding-right: 10px;
}

.logo>img {
    user-select: none;
}

.navbar-container>.nav-items-holder {
    display: flex;
    text-decoration: none;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.navbar-container>.nav-items-holder>.nav-item {
    font-family: 'Montserrat', sans-serif;
    font-size: var(--medium-font-size);
    font-weight: 500;
    color: var(--ternary-color);
    cursor: pointer;
    transition: all 200ms ease;
}

.navbar-container>.nav-items-holder>.nav-item:hover,
.navbar-container>.nav-items-holder>.nav-item.active {
    color: var(--primary-color);
}

.navbar-container>.nav-items-holder>.nav-item:active {
    transform: scale(.98);
}

.navbar-container>.hamburger-holder {
    display: none;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 10px;
    z-index: 2;
}

.navbar-container>.hamburger-holder>.hamburger-line-1,
.navbar-container>.hamburger-holder>.hamburger-line-2 {

    position: absolute;
    height: 1px;
    width: 100%;
    background: var(--ternary-color);
    transition-property: transform, top;
    transition-delay: 0ms, 160ms;
    transition-duration: 200ms;
}

.navbar-container>.hamburger-holder>.hamburger-line-1 {
    top: -4px;
}

.navbar-container>.hamburger-holder>.hamburger-line-2 {
    top: 4px;
}

.navbar-container>.hamburger-holder.active>.hamburger-line-1 {
    top: 0;
    transform: rotateZ(45deg);
    transition-property: top, transform;
    transition-delay: 0ms, 160ms;
    transition-duration: 200ms;
}

.navbar-container>.hamburger-holder.active>.hamburger-line-2 {
    top: 0;
    transform: rotateZ(-45deg);
    transition-property: top, transform;
    transition-delay: 0ms, 160ms;
    transition-duration: 200ms;
}

.nav-overlay {
    position: fixed;
    background: var(--secondary-color);
    height: var(--navbar-height);
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

@media only screen and (max-width: 1280px) {
    .navbar-container>.nav-items-holder {
        gap: 26px;
    }
}

@media only screen and (max-width: 1024px) {
    .logo {
        width: 200px;
        min-width: unset;
    }
}

@media only screen and (max-width: 840px) {
    .navbar-container>.nav-items-holder {
        display: none;
    }

    .navbar-container>.hamburger-holder {
        display: flex;
    }
}