@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #1897FF;

  --secondary-color: #000;
  --ternary-color: #fff;
  --footer-text-color: #CDCDCD;

  --heading-font-size: 50px;
  --submediumheading-font-size: 36px;
  --subheading-font-size: 30px;
  --medium-font-size: 18px;
  --small-font-size: 16px;
  --tiny-font-size: 14px;

  --paragraph-font-size: 18px;


  --thin-font-weight: 100;
  --extralight-font-weight: 200;
  --light-font-weight: 300;
  --regular-font-weight: 400;
  --medium-font-weight: 500;
  --semibold-font-weight: 600;
  --bold-font-weight: 700;
  --extrabold-font-weight: 800;
  --maxbold-font-weight: 900;

  --container-padding: 90px;
  --default-padding: 22px;
  --regular-padding: 18px;

  --regular-gap: 40px;
  --medium-gap: 100px;


  --navbar-height: 80px;
  --submenu-height: 280px;

  --submenu-blur: 24px;

  --banner-description-size: 50px;

  --puzzle-box-size: 8px;
  --puzzle-position: 15px;

  --nav-max-width: 1440px;

  /* dashboard */


  --sidebar-width: 260px;

}

body {
  background-color: var(--secondary-color);
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  max-width: var(--nav-max-width);
  margin: auto;
}

.section-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--medium-gap);
}

.wrapper-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 120px;
  transition: filter 200ms ease;
  padding: 0 var(--container-padding);
  overflow: hidden;
  min-height: 100vh;
}

.wrapper-container.blur {
  filter: blur(var(--submenu-blur));
}

img {
  max-width: 100%;
  object-fit: contain;
  user-select: none;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  word-break: break-word;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

.btn {
  position: relative;
  padding: 12px 20px;
  width: fit-content;
  font-family: 'Montserrat', sans-serif;
  font-size: var(--medium-font-size);
  font-weight: 500;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  cursor: pointer;
  transition: all 200ms ease;
  outline: none;
  border: none;
  user-select: none;
}

.btn.btn1 {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.btn.btn2 {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.btn.btn3 {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.btn:active {
  transform: scale(.98);
}

.btn::after,
.btn::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
}

.btn::after {
  top: 0;
  left: 0;
}

.btn::before {
  bottom: 0;
  right: 0;
}

.btn.btn1::after,
.btn.btn1::before {
  background: var(--secondary-color);
}

.btn.btn2::after,
.btn.btn2::before {
  background: var(--primary-color);
}

.btn.btn3::after,
.btn.btn3::before {
  background: var(--ternary-color);
}

.none {
  display: none !important;
}

.overlay {
  position: fixed;
  background: #000000a6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all 200ms ease;
}

::-moz-selection {
  color: var(--secondary-color);
  background: var(--ternary-color);
}

::selection {
  color: var(--secondary-color);
  background: var(--ternary-color);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary-color);
  /* background: #f5f5f5; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
  transition: all 200ms ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--secondary-color);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--secondary-color);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--secondary-color);
}

.loader-holder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-color);
  z-index: 300;
  width: 100%;
  height: 100%;
}

.loader-holder.hide {
  pointer-events: all;
  animation: closeloader 1s linear forwards;
}

@keyframes closeloader {
  0%, 90% {
    display: flex;
  }

  100% {
    display: none;
  }
}

.loader {
  box-sizing: border-box;
  position: relative;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}

.loader:after, .loader:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  background: var(--primary-color);
  animation: spin 1s linear infinite;
  transform-origin: 0px 100%;
}

.loader:before {
  transform-origin: 0 50%;
  background: var(--ternary-color);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 1024px) {
  :root {
    --heading-font-size: 44px;
    --subheading-font-size: 28px;
    --medium-font-size: 16px;
    --small-font-size: 14px;
    --tiny-font-size: 12px;

    --paragraph-font-size: 16px;

    --regular-gap: 46px;
    --medium-gap: 86px;
    --container-padding: 70px;

  }
}

@media only screen and (max-width: 990px) {
  :root {
    --banner-description-size: 35px;
  }
}

@media only screen and (max-width: 940px) {
  :root {
    --submenu-height: 380px;
    --puzzle-box-size: 6px;
  }
}

@media only screen and (max-width: 840px) {
  :root {
    --submenu-blur: 0px;
  }
}

@media only screen and (max-width: 881px) {
  :root {

    --heading-font-size: 38px;
    --subheading-font-size: 22px;

    --paragraph-font-size: 10px;

    --regular-gap: 40px;
    --medium-gap: 80px;

    --default-padding: 14px;
    --regular-padding: 10px;
    --puzzle-box-size: 4px;
    --puzzle-position: 12px;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    --container-padding: 50px;
    --banner-description-size: 30px;
    --puzzle-box-size: 12px;
    --puzzle-position: 25px;
  }
}

@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 960px) {}


@media only screen and (max-width: 640px) {
  :root {

    --heading-font-size: 34px;
    --subheading-font-size: 18px;

    --paragraph-font-size: 8px;

    --regular-gap: 36px;
    --medium-gap: 78px;

    --default-padding: 12px;
    --regular-padding: 8px;
    --banner-description-size: 20px;
  }
}

@media only screen and (max-width: 510px) {
  :root {
    --banner-description-size: 20px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 14px;
  }

}

@media only screen and (max-width: 480px) {}

@media only screen and (max-width: 400px) {
  :root {
    --banner-description-size: 16px;
    --puzzle-position: 16px;
    --puzzle-box-size: 8px;
  }
}


@media only screen and (max-width: 380px) {
  :root {
    --container-padding: 30px;
  }
}

@media only screen and (max-width: 320px) {
  :root {
    --banner-description-size: 14px;
    --container-padding: 15px;
    --puzzle-box-size: 6px;
  }

  .btn {
    font-size: 12px;
  }
}