.banner-image {
    text-align: center;
    margin-top: 100px;
}

.content-block {
    width: 65%;
}

.piece-design-sequence-container {
    display: flex;
    padding: var(--default-padding);
    position: relative;
    left: calc(var(--default-padding) * -3);
    padding-left: 0;
    width: calc(100% + calc(var(--default-padding) * 3));
}

.piece-design-sequence-container>.piece-design-item {
    display: flex;
    background: var(--primary-color);
    font-size: var(--medium-font-size);
    color: var(--secondary-color);
    font-weight: var(--semibold-font-weight);
    font-family: 'Poppins', sans-serif;
    padding: 40px var(--default-padding);
    padding-left: 0;
}

.piece-design-sequence-container>.piece-design-item:nth-child(even) {
    background-color: var(--ternary-color);
}

.piece-design-sequence-container>.piece-design-item>div {
    position: relative;
    display: flex;
    left: calc(var(--default-padding) * 5);
    background: inherit;
    padding: var(--regular-padding) 0;
    align-items: center;
    padding-right: var(--default-padding);
    word-break: break-word;
}


@media only screen and (max-width: 768px) {

    .content-block {
        width: 100%;
        text-align: center;
    }

    .piece-design-sequence-container {
        flex-wrap: wrap;
        width: calc(100% + calc(var(--default-padding) * 6));
        padding-left: unset;
    }

    .piece-design-sequence-container>.piece-design-item {
        width: calc(100% - calc(var(--default-padding) * 5));
    }

    .piece-design-sequence-container>.piece-design-item:nth-child(even) {
        margin-left: auto;
    }

    .piece-design-sequence-container>.piece-design-item:nth-child(even)>div {
        margin-left: unset;
        left: calc(var(--default-padding) * -4);
        padding-left: calc(var(--default-padding) * 4);
        padding-right: unset;
    }

    .piece-design-sequence-container>.piece-design-item>div {
        margin-left: auto;
        padding-right: calc(var(--default-padding) * 4);
    }
}