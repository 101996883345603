.home-section {
    display: flex;
    flex-direction: column;
    gap: var(--regular-gap);
}

.home-section>.left-block {
    display: flex;
    gap: var(--regular-gap);
    flex-direction: column;
    width: 50%;
}

.home-section>.right-block {
    margin: auto;
}

.home-section.welcome-section {
    flex-direction: row;
    justify-content: space-between;
}

.we-cover-section {
    display: flex;
}

.we-cover-section>.content-container {
    display: flex;
    flex-direction: column;
    gap: var(--regular-gap);
    width: 50%;
}

.we-cover-section>.content-container>.content-block {
    width: 100%;
}

.we-cover-section>.technologies-block {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.we-cover-section>.technologies-block>.technologies-block-item {
    display: flex;
    padding: 16px;
    justify-content: center;
    user-select: none;
    justify-self: flex-start;
}

.we-cover-section>.technologies-block>.technologies-block-item:first-child {
    grid-row-start: 1;
    grid-row-end: 3;
}

.we-cover-section>.technologies-block>.technologies-block-item:nth-last-child(2)>img {
    align-self: flex-end;
}

.we-cover-section>.technologies-block>.technologies-block-item:nth-last-child(1)>img {
    align-self: self-start;
}


.why-yuga-shift-section>.why-yuga-shift-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 60px;
}

.why-yuga-shift-block-item {
    display: flex;
    flex-direction: column;
}

.why-yuga-shift-block-item:first-child {}

.why-yuga-shift-block-item:first-child>img {
    width: calc(100% + calc(var(--container-padding) * 2));
    position: relative;
    left: calc(var(--container-padding) * -1);
    max-width: unset;
}

.why-yuga-shift-block-item:last-child>img {
    width: calc(100% + calc(var(--container-padding) * 2));
    position: relative;
    right: calc(var(--container-padding) * -1);
    max-width: unset;
}

.why-yuga-shift-block-item>img {
    /* width: 100px; */
    height: 150px;
    display: flex;
    align-self: center;
}

.why-yuga-shift-block-item>.b-text-content {
    margin-top: 38px;
    font-size: 20px;
    color: var(--ternary-color);
    font-weight: var(--bold-font-weight);
    font-family: 'Montserrat', sans-serif;
    /* padding: calc(var(--regular-padding) * 2) 0; */
    transition: all 200ms ease;
    overflow-wrap: break-word;
}

.why-yuga-shift-block-item>.s-text-content {
    margin-top: 4px;
    font-size: 14px;
    color: var(--ternary-color);
    font-weight: var(--regular-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
}

.puzzle-flip-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* background: var(--primary-color); */
    position: relative;
    left: calc(calc(var(--container-padding) * -1) + var(--puzzle-position));
    min-width: calc(100% + calc(var(--container-padding) * 2) + calc(var(--puzzle-position) * 2));
}

.puzzle-flip-container>.puzzle-hover-card-holder:nth-child(even) {
    top: 100%;
}

.puzzle-flip-container>.puzzle-hover-card-holder:nth-child(2) {
    top: calc(100% - var(--puzzle-position));
    left: calc(var(--puzzle-position) * -1);
}

.puzzle-flip-container>.puzzle-hover-card-holder:nth-child(3) {
    top: 0;
    left: calc(var(--puzzle-position) * -2);
}

.puzzle-flip-container>.puzzle-hover-card-holder:nth-child(4) {
    top: calc(100% + calc(var(--puzzle-position) * -1));
    left: calc(var(--puzzle-position) * -3);
}

.puzzle-flip-container>.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: var(--primary-color);
    bottom: 0;
    z-index: -1;
    height: calc(100% - var(--puzzle-position));
}

.puzzle-flip-container:nth-child(2)>.background-overlay {
    height: calc(100% - calc(var(--puzzle-position) * 2));
}

.puzzle-flip-section>.puzzle-flip-container:not(:first-child) {
    top: calc(var(--puzzle-position) * -1);
    left: calc(calc(var(--container-padding) * -1) + var(--puzzle-position));
}

.puzzle-flip-section>.puzzle-flip-container:not(:first-child)>.puzzle-hover-card-holder:nth-child(1) {
    top: calc(var(--puzzle-position) * -1);
}

.puzzle-flip-section>.puzzle-flip-container:not(:first-child)>.puzzle-hover-card-holder:nth-child(2) {
    top: calc(100% + calc(var(--puzzle-position) * -2));
}

.puzzle-flip-section>.puzzle-flip-container:not(:first-child)>.puzzle-hover-card-holder:nth-child(3) {
    top: calc(var(--puzzle-position) * -1);
}

.puzzle-flip-section>.puzzle-flip-container:not(:first-child)>.puzzle-hover-card-holder:nth-child(4) {
    top: calc(100% + calc(var(--puzzle-position) * -2));
}

.puzzle-hover-card-holder {
    position: relative;
}

.puzzle-hover-card-holder>.hover-card {
    position: relative;
    display: flex;
    background: var(--secondary-color);
    align-items: center;
    color: var(--ternary-color);
    transition: all 200ms ease, transform 1500ms;
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    font-weight: var(--medium-font-weight);
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.puzzle-hover-card-holder>.hover-card>.border-line {
    position: absolute;
    top: var(--puzzle-box-size);
    left: var(--puzzle-box-size);
    right: var(--puzzle-box-size);
    bottom: var(--puzzle-box-size);
    border: var(--puzzle-box-size) solid var(--primary-color);
}

.hover-card>.center-top-right-dot {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--primary-color);
    top: 0px;
    right: 0px;
    z-index: 2;
}

.hover-card>.center-top-left-dot {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--primary-color);
    top: 0px;
    left: 0px;
    z-index: 2;
}

.hover-card>.center-bottom-left-dot {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--primary-color);
    bottom: 0px;
    left: 0px;
    z-index: 2;
}

.hover-card>.center-bottom-right-dot {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--primary-color);
    bottom: 0px;
    right: 0px;
    z-index: 2;
}

.hover-card>.top-left-dot,
.hover-card>.top-right-dot,
.hover-card>.bottom-left-dot,
.hover-card>.bottom-right-dot {
    position: absolute;
    width: calc(var(--puzzle-position) * 2);
    height: calc(var(--puzzle-position) * 2);
    background: var(--secondary-color);
    z-index: 1;
}

.hover-card>.top-left-dot {
    top: var(--puzzle-box-size);
    left: var(--puzzle-box-size);
    border-right: var(--puzzle-box-size) solid var(--primary-color);
    border-bottom: var(--puzzle-box-size) solid var(--primary-color);
}

.hover-card>.top-right-dot {
    top: var(--puzzle-box-size);
    right: var(--puzzle-box-size);
    border-left: var(--puzzle-box-size) solid var(--primary-color);
    border-bottom: var(--puzzle-box-size) solid var(--primary-color);
}

.hover-card>.bottom-left-dot {
    bottom: var(--puzzle-box-size);
    left: var(--puzzle-box-size);
    border-right: var(--puzzle-box-size) solid var(--primary-color);
    border-top: var(--puzzle-box-size) solid var(--primary-color);
}

.hover-card>.bottom-right-dot {
    bottom: var(--puzzle-box-size);
    right: var(--puzzle-box-size);
    border-left: var(--puzzle-box-size) solid var(--primary-color);
    border-top: var(--puzzle-box-size) solid var(--primary-color);
}

.puzzle-hover-card-holder>.hover-card>p {
    position: absolute;
    padding: calc(var(--puzzle-box-size) + var(--puzzle-box-size) + var(--puzzle-box-size));
    transition: transform 400ms ease, opacity 200ms ease;
    width: calc(100% - (var(--puzzle-box-size) * 4));
    max-height: calc(100% - (var(--puzzle-box-size) * 4));
    left: calc(var(--puzzle-box-size) * 2);
    line-height: 1.4;
}

.puzzle-hover-card-holder>.hover-card:hover>.s-text-content-2 {
    transform: translateX(0);
    opacity: 1;
}

.puzzle-hover-card-holder>.hover-card:hover>.s-text-content-1 {
    transform: translateX(-100%);
    opacity: 0;
}

.s-text-content-1 {
    font-size: 30px;
    font-weight: var(--semibold-font-weight);
    width: 100%;
    overflow-wrap: break-word;
}

.s-text-content-1>span {
    color: var(--primary-color);
    overflow-wrap: break-word;
}

.s-text-content-2 {
    font-size: 18px;
    font-weight: var(--medium-font-weight);
    transform: translateX(30px);
    opacity: 0;
}

.puzzle-hover-card-holder:nth-child(3)>.hover-card>.center-top-left-dot,
.puzzle-flip-container:nth-child(1)>.puzzle-hover-card-holder:nth-child(3)>.hover-card>.top-left-dot,
.puzzle-flip-container:nth-child(2)>.puzzle-hover-card-holder:nth-child(2)>.hover-card>.bottom-left-dot,
.puzzle-hover-card-holder:nth-child(4)>.hover-card>.center-bottom-left-dot {
    display: none;
}

@media only screen and (max-width: 1300px) {
    .s-text-content-1 {
        font-size: 24px;
    }

    .s-text-content-2 {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1200px) {
    .s-text-content-1 {
        font-size: 22px;
    }

    .s-text-content-2 {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1100px) {}

@media only screen and (max-width: 1024px) {
    .why-yuga-shift-section>.why-yuga-shift-block {
        grid-template-columns: 1fr 1fr;
    }

    .s-text-content-1 {
        font-size: 18px;
    }

    .s-text-content-2 {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1000px) {
    .s-text-content-2 {
        font-size: 10px;
    }
}

@media only screen and (max-width: 960px) {

    .home-section.welcome-section {
        flex-direction: column;
    }

    .home-section>.left-block {
        width: 100%;
    }
}

@media only screen and (max-width: 881px) {

    .hover-card>.center-bottom-right-dot,
    .hover-card>.center-bottom-left-dot,
    .hover-card>.center-top-left-dot,
    .hover-card>.center-top-right-dot {
        width: 14px;
        height: 14px;
    }

    .s-text-content-1 {
        font-size: 16px;
    }

    .s-text-content-2 {
        font-size: 9px;
    }
}


@media only screen and (max-width: 840px) {
    .we-cover-section {
        flex-direction: column;
        gap: var(--regular-gap);
    }

    .we-cover-section>.content-container {
        width: 100%;
    }

    .we-cover-section>.technologies-block {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .puzzle-flip-container {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        left: 0 !important;
        grid-gap: 35px;
        min-width: auto;
    }

    .puzzle-hover-card-holder>.hover-card>p {
        width: calc(100% - (var(--puzzle-box-size) * 2));
        left: calc(var(--puzzle-box-size) * 1);
    }

    .s-text-content-1 {
        font-size: 50px;
    }

    .s-text-content-2 {
        font-size: 28px;
    }

    .puzzle-flip-section {
        display: grid;
        grid-gap: 35px;
        background: var(--primary-color);
    }

    .puzzle-hover-card-holder {
        position: static;
    }

    .puzzle-flip-section>.puzzle-flip-container:not(:first-child) {
        top: 0;
        left: 0;
    }

    .puzzle-hover-card-holder>.hover-card>.border-line {
        left: 0;
        right: 0;
    }

    .hover-card>.top-left-dot {
        left: 0;
    }

    .hover-card>.bottom-right-dot {
        right: 0;
    }

    .puzzle-flip-container:nth-child(1)>.puzzle-hover-card-holder:nth-child(3)>.hover-card>.top-left-dot,
    .puzzle-hover-card-holder:nth-child(3)>.hover-card>.top-left-dot,
    .puzzle-hover-card-holder:nth-child(3)>.hover-card>.center-top-left-dot {
        display: flex;
    }

    .puzzle-hover-card-holder:nth-child(1)>.hover-card>.top-right-dot,
    .puzzle-hover-card-holder:nth-child(1)>.hover-card>.center-top-right-dot,
    .puzzle-hover-card-holder:nth-child(2)>.hover-card>.top-right-dot,
    .puzzle-hover-card-holder:nth-child(2)>.hover-card>.center-top-right-dot,
    .puzzle-hover-card-holder:nth-child(2)>.hover-card>.center-bottom-left-dot,
    .puzzle-hover-card-holder:nth-child(2)>.hover-card>.top-right-dot,
    .puzzle-hover-card-holder:nth-child(2)>.hover-card>.bottom-left-dot,
    .puzzle-hover-card-holder:nth-child(3)>.hover-card>.top-right-dot,
    .puzzle-hover-card-holder:nth-child(3)>.hover-card>.center-top-right-dot,
    .puzzle-hover-card-holder:nth-child(3)>.hover-card>.bottom-left-dot,
    .puzzle-hover-card-holder:nth-child(3)>.hover-card>.center-bottom-left-dot,
    .puzzle-hover-card-holder:nth-child(4)>.hover-card>.bottom-left-dot,
    .puzzle-hover-card-holder:nth-child(4)>.hover-card>.center-bottom-left-dot {
        display: none;
    }

    .puzzle-flip-container>.puzzle-hover-card-holder:nth-child(even),
    .puzzle-flip-section>.puzzle-flip-container:not(:first-child)>.puzzle-hover-card-holder:nth-child(2), .puzzle-flip-section>.puzzle-flip-container:not(:first-child)>.puzzle-hover-card-holder:nth-child(4) {
        top: unset;
    }

    .hover-card>.center-bottom-right-dot, .hover-card>.center-bottom-left-dot, .hover-card>.center-top-left-dot, .hover-card>.center-top-right-dot {
        width: 30px;
        height: 42px;
    }
}

@media only screen and (max-width: 640px) {
    .s-text-content-1 {
        font-size: 40px;
    }

    .s-text-content-2 {
        font-size: 24px;
    }

    .why-yuga-shift-section>.why-yuga-shift-block {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 580px) {
    .s-text-content-1 {
        font-size: 30px;
    }

    .s-text-content-2 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 520px) {
    .s-text-content-1 {
        font-size: 26px;
    }

    .s-text-content-2 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {

    .puzzle-flip-container,
    .puzzle-flip-section {
        grid-gap: 20px;
    }

    .s-text-content-1 {
        font-size: 24px;
    }

    .s-text-content-2 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 420px) {

    .s-text-content-1 {
        font-size: 22px;
    }

    .s-text-content-2 {
        font-size: 12px;
    }
}

@media only screen and (max-width: 400px) {

    .hover-card>.center-bottom-right-dot,
    .hover-card>.center-bottom-left-dot,
    .hover-card>.center-top-left-dot,
    .hover-card>.center-top-right-dot {
        width: 20px;
        height: 26px;
    }
}

@media only screen and (max-width: 320px) {

    .s-text-content-1 {
        font-size: 16px;
    }

    .s-text-content-2 {
        font-size: 10px;
        padding: 10px !important;
    }
}

@media only screen and (max-width: 260px) {

    .s-text-content-1 {
        font-size: 14px;
    }

    .s-text-content-2 {
        font-size: 9px;
    }
}