.menu-dropdown-item-holder {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--secondary-color);
    width: 100%;
    height: 0;
    opacity: 0;
    height: 0;
    transition: all 100ms ease;
    z-index: 1;
    overflow: hidden;
    padding: 0 var(--container-padding);
}

.menu-dropdown-item-holder.open {
    opacity: 1;
    height: var(--submenu-height);
    transition: all 200ms ease;
}

.menu-dropdown-item-holder>.menu-dropdown-item-container {
    display: flex;
    max-width: calc(var(--nav-max-width) - (var(--default-padding) * 2));
    margin: auto;
    background-color: transparent;
    height: 100%;
}

.menu-dropdown-item-container>.menu-dropdown-item-content {
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--ternary-color);
    padding: var(--default-padding) 0;
    max-width: 275px;
    gap: var(--default-padding);
}

.menu-dropdown-item-container>.menu-dropdown-item-content::after {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    background: var(--ternary-color);
    top: var(--default-padding);
    right: 0;
}

.menu-dropdown-item-container>.menu-dropdown-item-content>.menu-dropdown-item-content-heading {
    font-weight: var(--medium-font-weight);
    font-family: 'Montserrat', sans-serif;
    font-size: var(--subheading-font-size);
    transition: all 200ms ease;
    transform: translateY(-30px);
    opacity: 0;
}

.menu-dropdown-item-holder.open>.menu-dropdown-item-container>.menu-dropdown-item-content>.menu-dropdown-item-content-heading {
    transform: translateY(0) translateX(0);
    opacity: 1;
}

.menu-dropdown-item-container>.menu-dropdown-item-content>.menu-dropdown-item-content-description {
    font-size: 16px;
    font-weight: var(--light-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
    padding-right: 20px;
    transform: translateY(-30px);
    opacity: 0;
}

.menu-dropdown-item-holder.open>.menu-dropdown-item-container>.menu-dropdown-item-content>.menu-dropdown-item-content-description {
    transform: translateY(0) translateX(0);
    opacity: .5;
}

.menu-dropdown-item-container>.menu-dropdown-item-list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 48px;
    gap: var(--default-padding);
    font-family: 'Poppins', sans-serif;
    padding-right: 0;
}

.menu-dropdown-item-container>.menu-dropdown-item-list-content>.menu-dropdown-list-heading {
    color: var(--ternary-color);
    font-weight: var(--regular-font-weight);
    text-transform: uppercase;
    font-size: var(--small-font-size);
    letter-spacing: 4px;
    transition: all 500ms ease;
    transform: translateY(-30px);
    opacity: 0;
}

.menu-dropdown-item-holder.open>.menu-dropdown-item-container>.menu-dropdown-item-list-content>.menu-dropdown-list-heading {
    transform: translateY(0) translateX(0);
    opacity: .5;
}

.menu-dropdown-item-container>.menu-dropdown-item-list-content>.menu-dropdown-item-list-content-subitem-holder {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
    height: calc(100% - 48px);
    grid-gap: 20px;
}

.menu-dropdown-item-container>.menu-dropdown-item-list-content>.menu-dropdown-item-list-content-subitem-holder>.menu-dropdown-item-list-content-subitem-item {
    color: var(--ternary-color);
    font-family: 'Poppins', sans-serif;
    font-weight: var(--regular-font-weight);
    font-size: var(--medium-font-size);
    cursor: pointer;
    width: fit-content;
    transition: all 400ms ease, font-weight 100ms ease;
    transform: translateY(-30px);
    opacity: 0;
    align-self: center;
}

.menu-dropdown-item-holder.open>.menu-dropdown-item-container>.menu-dropdown-item-list-content>.menu-dropdown-item-list-content-subitem-holder>.menu-dropdown-item-list-content-subitem-item {
    transform: translateY(0) translateX(0);
    opacity: 1;
}

.menu-dropdown-item-container>.menu-dropdown-item-list-content>.menu-dropdown-item-list-content-subitem-holder>.menu-dropdown-item-list-content-subitem-item:hover {
    color: var(--primary-color);
}

.menu-dropdown-item-holder.open>.submenu-overlay {
    display: flex;
}

.submenu-overlay {
    display: none;
    position: fixed;
    top: var(--navbar-height);
    background-color: var(--secondary-color);
    left: 0;
    right: 0;
    z-index: -1;
    height: 0;
    height: calc(var(--submenu-height) + 50px);
    transition: height 200ms ease, opacity 100s ease;
}

@media only screen and (max-width: 940px) {
    :root {
        --submenu-height: 350px;
    }
}

@media only screen and (max-width: 840px) {
    .menu-dropdown-item-holder {
        display: none;
    }
}