.privacy-content-block,
.privacy-content-block ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.privacy-content-block .b-text-content {
    font-size: 16px;
    color: var(--ternary-color);
    font-weight: var(--bold-font-weight);
    font-family: 'Montserrat', sans-serif;
    transition: all 200ms ease;
    overflow-wrap: break-word;
    color: var(--primary-color);
}

.privacy-content-block .s-text-content {
    font-size: 14px;
    color: var(--ternary-color);
    font-weight: var(--medium-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
}

.privacy-content-block .s-text-content>a {
    color: var(--primary-color);
    text-decoration: underline;
}

.privacy-content-block ul li {
    list-style: inside;
}

.privacy-content-block ul li.text-indent {
    text-indent: 24px;
}