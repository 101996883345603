.dashboard-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    min-height: 100vh;
    background: var(--ternary-color);
    grid-gap: 8px;
}

/* navbar */
.navbar {
    grid-column-start: 1;
    grid-column-end: 8;
    background-color: var(--ternary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    box-shadow: 0 0 2px #ccc;
    height: 50px;
    /* height: var(--navbar-height); */
}

.logo_item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 22px;
    font-weight: 500;
    color: var(--primary-color);
}

.navbar img {
    width: 250px;
}

.navbar_content {
    display: flex;
    align-items: center;
    column-gap: 25px;
}

.navbar_content>* {
    cursor: pointer;
    font-size: 20px;
    color: #b6b6b6;
}

.dashboard-content-container {
    grid-column-start: 2;
    grid-column-end: 8;
    margin: 0 8px;
    border-radius: 8px;
    height: calc(100vh - 70px);
    overflow: auto;
}