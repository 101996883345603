.about-section {
    display: flex;
    margin-top: 100px;
}

.content-block {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 30px;
    transition: all 200ms ease;
}

.content-block.right {
    margin-left: auto;
}

.content-block>.b-text-content {
    font-size: var(--heading-font-size);
    color: var(--ternary-color);
    font-weight: var(--bold-font-weight);
    font-family: 'Montserrat', sans-serif;
    transition: all 200ms ease;
    /* overflow-wrap: break-word; */
}

.content-block>.b-text-content>span,
.content-block>.s-text-content>span {
    color: var(--primary-color);
}

.content-block>.s-text-content {
    font-size: var(--medium-font-size);
    color: var(--ternary-color);
    font-weight: var(--regular-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
}

.content-block>.s-text-content>span {
    font-weight: var(--bold-font-weight);
}

/* Our Mission */

.our-mission-section {
    display: flex;
    flex-direction: column;
    gap: var(--regular-gap);
}

.mission-platform-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: var(--default-padding);
    justify-content: space-evenly;
}

.mission-platform-list>li {
    display: flex;
    color: var(--ternary-color);
    font-size: var(--medium-font-size);
    align-items: center;
    gap: calc(var(--default-padding) / 2);
    font-weight: var(--regular-font-weight);
    font-family: 'Poppins', sans-serif;
    word-break: break-word;
}

.mission-platform-list>li>img {
    width: 100px;
}

.mission-platform-list>li:nth-child(7)>img {
    width: 250px;
}

.mission-platform-list>li:nth-child(8)>img {
    width: 180px;
}

.piece-design-block {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--primary-color);
    padding: calc(var(--regular-padding) * 3) 0;
    margin-right: calc(var(--regular-padding) * 6);
    width: 65%;
    margin-top: 58px;
}

.piece-design-block>.piece-design {
    position: absolute;
    background: var(--primary-color);
    top: 0;
    left: calc(var(--container-padding) * -1);
    right: 0;
    bottom: 0;
    z-index: -1;
}

.piece-design-block>.s-text-content {
    position: relative;
    width: calc(100% + calc(var(--default-padding) * 6));
    background: var(--primary-color);
    padding: calc(var(--regular-padding) * 2) calc(var(--regular-padding) * 8) calc(var(--regular-padding) * 2) 0;
    font-size: var(--medium-font-size);
    color: var(--secondary-color);
    font-weight: var(--medium-font-weight);
    font-family: 'Poppins', sans-serif;
    transition: all 200ms ease;
}


/* Our Team */

.our-team-section {
    display: flex;
    flex-direction: column;
    gap: var(--medium-gap);
}


.piece-design-block.right {
    background-color: var(--ternary-color);
    align-self: flex-end;
    margin-left: calc(var(--regular-padding) * 6);
    margin-right: unset;
}

.piece-design-block.right>.piece-design {
    background-color: var(--ternary-color);
    right: calc(var(--container-padding) * -1);
    left: 100%;
}

.piece-design-block.right>.s-text-content {
    background-color: var(--ternary-color);
    padding: calc(var(--regular-padding) * 2) 0 calc(var(--regular-padding) * 2) calc(var(--regular-padding) * 10);
    left: calc(var(--default-padding) * -6);
}

@media only screen and (max-width: 1024px) {
    .about-section {
        flex-direction: column;
        gap: var(--default-padding) 0;
    }

    .content-block {
        width: 100%;
    }

    .image-block {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: var(--default-padding);

    }

    .image-block>.graphic1 {
        position: relative;
        padding: var(--default-padding);
        top: 0;
    }

    .image-block>.graphic2 {
        position: relative;
        width: 300px;
    }

    .mission-platform-list {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .piece-design-block {
        width: 80%;
    }

}

@media only screen and (max-width: 768px) {
    .mission-platform-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .mission-platform-list {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .mission-platform-list>li {
        font-size: 24px;
        gap: 24px;
    }

    .mission-platform-list>li>img {
        width: 35%;
    }

    .mission-platform-list>li:nth-child(7)>img,
    .mission-platform-list>li:nth-child(8)>img {
        width: 80%;
    }
}

@media only screen and (max-width: 300px) {
    .mission-platform-list>li {
        flex-direction: column;
    }

    .mission-platform-list>li>img {
        width: 70%;
    }
}